import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '@toss/react';

import { Error404Widget } from './error-widgets';
import { ErrorPageWrapper } from './ErrorPageWrapper';

export const Error404PageView = () => {
  const { ready } = useTranslation(['common']);
  const isMounted = useIsMounted();

  if (!isMounted || !ready) return null;

  return (
    <ErrorPageWrapper>
      <Error404Widget />
    </ErrorPageWrapper>
  );
};
