import { PropsWithChildren, ReactElement } from 'react';

export const EmptyLayout = (props: PropsWithChildren) => {
  return <>{props.children}</>;
};

export const GrayEmptyLayout = (props: PropsWithChildren) => {
  return <div className="h-screen w-full bg-gray-50">{props.children}</div>;
};

export const getEmptyLayout = (page: ReactElement) => (
  <EmptyLayout>{page}</EmptyLayout>
);

export const getGrayEmptyLayout = (page: ReactElement) => (
  <GrayEmptyLayout>{page}</GrayEmptyLayout>
);
