import { useIsomorphicLayoutEffect } from '@toss/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Error404PageView } from '@zep/web-components';

import { getEmptyLayout } from '../components/layouts/EmptyLayout';

const Page404 = () => {
  const { locale } = useRouter();
  const { i18n } = useTranslation();

  useIsomorphicLayoutEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  return <Error404PageView />;
};
Page404.getLayout = getEmptyLayout;

export default Page404;
